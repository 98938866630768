.prompt {
    align-items: center;
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    justify-content: flex-start;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.prompt-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    /* padding: 0 16px 56px 16px; */
    padding: 0 16px 78px 16px;
    position: relative;
    /* Use top rather than padding so that close button is not covered */
    top: 72px;
    width: 100vw;
}

.prompt-image {
    align-self: stretch;
    border-radius: 24px;
    height: 166px;
    overflow: hidden;
}

.prompt-image > img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.prompt-content > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    z-index: 0;
}

.prompt-page-content {
    gap: 24px;
    width: 100%;
}

/* Prompt Header */

.prompt-header {
    gap: 24px;
    margin: 0px 24px;
    text-align: center;
}

.prompt-header > h2 {
    font-size: 24px;
    line-height: 34px;
}

.prompt-header > p {
    line-height: 24px;
}

/* Prompt Footer */

.prompt-footer-wrapper {
    bottom: 0;
    left: 0;
    position: relative;
    width: 100%;
}

.prompt-footer {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    padding: 16px 0 40px 0;
    width: inherit;
}

.prompt-footer > div {
    align-self: stretch;
}

.prompt-footer > div > div {
    height: 100%;
}

.need-advice {
    background-color: #2A935A !important;
    color: white;
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.need-advice > p {
    width: 134px;
}

/* Prompt Content - ChoiceCard.jsx */

.prompt-choice-card {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.choice-avatar {
    margin-bottom: -49px;
    z-index: 0;
}

.choice-content {
    border-radius: 24px;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 64px 24px 24px 24px;
    text-align: center;
}

.choice-buttons {
    display: flex;
    flex-direction: column;
    gap: 8px 16px;
}

.multiple-choice-buttons {
    row-gap: 16px;
}

/* Prompt Content - Product/Investment Options */

.product-options-wrapper {
    align-items: flex-start !important;
    margin-top: 16px;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
}

.product-options {
    display: flex;
    flex-direction: row !important;
    gap: 12px;
    width: fit-content;
}

.product-option {
    background-color: white;
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-height: 354px;
    justify-content: space-between;
    padding: 8px;
    text-wrap: balance;
    width: 280px;
}

.product-option-title {
    align-items: center;
    border-radius: var(--border-radius);
    display: flex;
    font-size: 13px;
    gap: 8px;
    height: 40px;
}

.product-option-title:hover {
    cursor: pointer;
}

.product-option-image {
    display: flex;
    justify-content: center;
    height: 79px;
}

.product-option-image > img {
    height: 100%;
}

.product-option-text {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
}

/* Prompt Content - Product/Investment Distribution */

.product-ratios {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.product-ratio {
    align-items: flex-end;
    background-color: white;
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;
    padding: 24px;
    width: 100%;
}

.product-ratio-text {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 67%;
}

.product-ratio-input {
    border: 1px solid #BDBDBD !important;
    width: 33%;
}

.product-ratio-input > label {
    font-size: medium !important;
}

.product-ratio-input > input[type=text] {
    font-size: x-large !important;
    width: 100%;
}

/* Prompt Content - Buttons Components */

.button-choice {
    background-color: inherit;
    border: 1px solid #FFF;
    color: white;
    height: fit-content !important;
    min-height: 48px;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.button-choice:hover {
    border: 2px solid #FFF;
}

.button-choice:active {
    background-color: #472A8D;
    border: 0;
}

.button-continue {
    font-weight: var(--font-regular);
}

.button-skip {
    background-color: inherit !important;
    color: white;
}

.button-select {
    background-color: inherit;
    border: 1px solid black;
}

.button-select-clicked {
    background-color: #F0F0F0;
}

.button-confirm {
    background-color: black;
    color: white;
    width: 100%;
}

/* Risk Levels Indicator and Popup */

.risk-level-indicator {
    align-items: center;
    border-radius: 28px;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    gap: 16px;
    height: 24px;
    justify-content: space-between;
    padding: 0 8px;
    width: fit-content;
}

.risk-level-indicator:hover {
    cursor: pointer;
}

.risk-levels-content {
    border-radius: var(--border-radius-popup);
    gap: 32px;
    top: 80px;
}

.risk-levels-content-title {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    height: 40px;
    justify-content: flex-start;
    margin: 24px 0 0 36px;
}

.risk-levels-section {
    padding: 16px 32px 32px 32px;
}

.risk-levels-section-header {
    gap: 12px;
    padding: 0 !important;
}

.risk-levels-icon {
    height: 32px;
    width: 32px;
}

.risk-levels-section-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

/* Tip Screen Popup */

.tip-screen {
    display: inline-flex !important;
}

.tip-screen-content {
    background-color: #156C3D !important;
    border-radius: var(--border-radius-popup);
    color: white;
    top: 56px;
}

.tip-screen-image {
    align-self: stretch;
    border-radius: var(--border-radius-popup);
    height: 198px;
    overflow: hidden;
}

.tip-screen-image > img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.tip-screen-text-wrapper {
    width: 100%;
}

.tip-screen-text {
    display: flex;
    flex-direction: column;
    /* gap: 40px; */
    margin: 80px 32px 0 24px;
}

.tip-screen-text > h2 {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 40px;
}

.tip-screen-text > h3 {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 24px;
    margin-top: 16px;
}

.tip-screen-text > p, .tip-screen-text > ol {
    line-height: 26px;
    margin-bottom: 26px;
}

.tip-screen-text > ol {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.tip-screen-button {
    background-color: #156C3D;
    bottom: 0;
    display: flex;
    position: sticky;
    width: 100%;
}

.tip-screen-button > button {
    margin: 16px 24px 40px 24px;
    width: 100%;
}

/* Outcome */

.outcome {
    align-items: center;
    backdrop-filter: blur(8px);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.outcome-success {
    background-color: rgba(0, 0, 0, 0.70);
}

.outcome-consequence {
    background-color: rgba(0, 0, 0, 0.85);
}

.outcome-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 56%;
    justify-content: center;
    padding-bottom: 78px;
    width: 100%;
}

#success-background {
    height: inherit;
    position: absolute;
}

#success-confetti {
    position: absolute;
}

.outcome-text {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 30px;
    text-align: center;
    z-index: 1;
}

.outcome-text > h1 {
    color: white;
    font-weight: var(--font-semi-bold);
    line-height: 42px;
}

.outcome-text > p {
    color: white;
    line-height: 26px;
}

.outcome-button {
    bottom: 78px;
    display: flex;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.outcome button {
    margin: 0 24px 40px 24px;
    width: 100%;
}

/* Styles for screens 360px and below */
@media (max-width: 360px) {
    .product-ratio {
        flex-direction: column;
    }

    .product-ratio-text {
        width: 100%;
    }

    .product-ratio-input {
        width: 60%;
    }
}

/* Styles for screens between 400px and 900px */
@media (min-width: 400px) and (max-width: 899px) {
    .prompt-content {
        width: 400px;
    }

    .product-options-wrapper {
        width: 85vw;
    }
}

/* Styles for screens 400px and above */
@media (min-width: 400px) {
    .outcome-content, .outcome-button {
        width: 400px;
    }
}

/* Styles for screens 900px and above */
@media (min-width: 900px) {
    .prompt-content {
        flex-direction: row;
        justify-content: space-evenly;
    }

    .prompt-image {
        height: auto;
        width: 50vw;
    }

    .prompt-page-content {
        width: 400px;
    }

    .product-options-wrapper {
        width: fit-content;
    }

    .product-ratios {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 810px;
    }

    .product-ratio {
        width: 400px;
    }

    .tip-screen-image {
        border-radius: 0;
    }

    .outcome-content, .outcome-button {
        width: 500px;
    }

    .outcome-text {
        padding: 30px 60px;
    }
}
