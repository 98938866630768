.game-map {
    /* background: linear-gradient(
        to bottom, #FDFBDC calc(100% - 100px), #0D8248 calc(100% - 100px)
    ); */
    background-color: #FDFBDC;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* min-height: 100%; */
    min-height: calc(100vh - 78px);
    overflow: hidden;
    overflow-x: auto;
    /* padding-bottom: 56px !important; */
    width: auto;
}

/* Header - title, net worth, financial guide */

.game-map > header {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    margin-top: 56px;
    position: fixed !important;
    top: 0;
    width: 100vw;
}

#net-worth-financial-guide {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: center;
    padding: 0 4px;
}

/* Map */

.map {
    align-items: flex-end;
    display: flex;
    position: relative;
}

.map > * {
    position: absolute;
}

.map, #map-path, #map-background {
    /* 100vh - margin-top - header-height - padding-bottom */
    /* height: calc(100vh - 56px - 110px - 56px); */
    /* 100vh - margin-top - header-height - bottom-nav-bar */
    height: calc(100vh - 56px - 110px - 78px);
}

/* Footer */

.game-map > footer {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: row;
    gap: 83px;
    justify-content: center;
    /* margin-bottom: 48px; */
    margin-bottom: 128px;
    position: fixed;
    width: 100vw;
}

#map-forward, #map-back {
    cursor: pointer;
    width: 66px;
}

/* Styles for screen height 480px and below */
@media (max-height: 600px) {
    .game-map > header {
        margin-top: 32px;
    }

    .map, #map-path, #map-background {
        /* 100vh - margin-top - header-height - padding-bottom */
        height: calc(100vh - 32px - 98px - 56px);
    }
}

/* Styles for screens with aspect ratio 3/2 or above */
@media (min-aspect-ratio: 3/2) {
    .game-map > header {
        flex-direction: row;
        justify-content: flex-start;
        margin-left: 56px;
    }

    @media (max-height: 600px) {
        .map, #map-path, #map-background {
            /* 100vh - margin-top - header-height - padding-bottom */
            height: calc(100vh - 56px - 44px - 56px);
        }
    }

    @media (min-height: 601px) and (max-height: 650px) {
        .map, #map-path, #map-background {
            /* 100vh - margin-top - header-height - padding-bottom */
            height: calc(100vh - 56px - 52px - 56px);
        }
    }
}

/* Styles for screens 1200px and above */
@media (min-width: 1200px) {
    #map-path, #map-background {
        height: auto;
        width: 100vw;
    }
}
