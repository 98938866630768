@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

:root {
    /* Inter Font */
    --inter: "Inter", sans-serif;
    --font-regular: 400;
    --font-medium: 500;
    --font-semi-bold: 600;
    --font-bold: 700;
    /* Colours */

    --border-radius: 24px;
    --border-radius-popup: 32px 32px 0px 0px;
}

html, body {
    margin: 0;
}

h1, h2, h3, p, div, button, input[type=submit] {
    font-family: var(--inter);
    margin: 0;
}

.text-highlight-light {
    color: #FFDCDC;
    font-weight: var(--font-semi-bold);
}

.text-highlight-dark {
    color: black;
    font-weight: var(--font-semi-bold)
}

button, input[type=submit] {
    border: 0;
    border-radius: 44px;
    color: black;
    font-size: 16px;
    font-weight: var(--font-medium);
    height: 48px;
    padding: 0 32px;
}

button:hover, input[type=submit] {
    cursor: pointer;
}

/* Adding padding-bottom to everything */

.game-onboarding,
.prompt,
.popup-content {
    min-height: 100%;
    overflow-y: auto;
    padding-bottom: 78px !important;
}

/* BankStatement Popup */

.bank-statement-content {
    border-radius: 0 !important;
    top: 0 !important;
}

#net-worth {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 80px 32px 16px 32px;
}

#net-worth h1 {
    font-weight: var(--font-semi-bold);
}

.finances-summary {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    font-weight: var(--font-medium);
    gap: 4px;
    justify-content: center;
    padding: 0px 48px 8px 48px;
}

.finances-summary div {
    align-items: center;
    align-self: stretch;
    border: 0;
    border-radius: 37px;
    display: flex;
    height: 48px;
    justify-content: space-between;
    padding: 0 24px;
}

#net-worth-chart {
    align-self: stretch;
    display: flex;
    padding: 0 0 8px 16px;
}

.income-section {
    background-color: #ffdcdc;
}

.savings-section {
    background-color: #B9EAFF;
}

.investments-section {
    background-color: #2A935A;
    color: white;
}

.pension-section {
    background-color: #FCF078;
}

.section-header {
    align-items: center;
    align-self: stretch;
    display: flex;
    height: 61px;
    padding: 8px 32px 0 32px;
}

.section-row {
    align-items: center;
    align-self: stretch;
    border-top: 0.5px solid rgba(0, 0, 0, 0.30);
    display: flex;
    height: 64px;
    justify-content: space-between;
    padding: 0 32px;
}

#monthly-spending {
    display: flex;
    flex-direction: column;
}

/* Dictionary (Financial Guide) Popup */

.dictionary-content {
    border-radius: var(--border-radius-popup);
    top: 48px;
}

.dictionary-header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 32px;
    text-align: center;
}

.dictionary-options {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 88px 0;
    width: 80%;
}

.dictionary-option {
    align-items: center;
    border-radius: 48px;
    display: flex;
    justify-content: space-between;
    padding: 8px 24px 8px 8px;
}

.dictionary-option:hover{
    cursor: pointer;
}

.dictionary-option-header {
    align-items: center;
    display: flex;
    gap: 16px;
}

/* Popup Classes - BankStatement, RiskLevels, Dictionary, Insight, Prompt */

.popup {
    /* background-color: rgba(0, 0, 0, 0.10); */
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.70);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    overflow-y: auto;
}
  
.popup-content {
    align-items: center;
    align-self: stretch;
    background-color: white;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding-bottom: 4px;
    position: absolute;
    width: 100%;
}

.close-popup {
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 24px;
}

.popup-sections {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    margin: 0 4px;
}

.popup-section {
    align-items: center;
    align-self: stretch;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.popup-container {
    position: relative;
    z-index: 9999;
}

/* Pop-up Buttons - MyNetWorth, FinancialGuide */

.popup-button {
    align-items: center;
    background-color: #FFF;
    border-radius: 40px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    font-size: 14px;
    gap: 8px;
    justify-content: center;
    min-height: 52px;
    padding: 6px 16px 6px 6px;
}

.popup-button:hover {
    background-color: #eee;
    cursor: pointer;
}

.popup-button-icon {
    align-items: center;
    border-radius: 51px;
    display: flex;
    justify-content: center;
    min-height: 40px;
    min-width: 40px;
}

.my-net-worth-icon {
    background-color: #FFDCDC;
}

.financial-guide-icon {
    background-color: #CEF2E7;
}

/* div containing StepIndicator and button - GameOnboarding, Insight */

.step-button-footer {
    align-items: center;
    align-self: stretch;
    background-color: inherit;
    bottom: 0;
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: space-between;
    padding: 16px 64px 64px 64px;
    position: relative;
}

.step-button-footer > button, .step-button-footer > input[type=submit] {
    font-size: 20px;
}

/* Styles for screen height 480px and below */
@media (max-height: 600px) {
    .popup-button {
        font-size: 12px;
        min-height: 42px;
    }

    .popup-button-icon {
        min-height: 30px;
        min-width: 30px;
    }

    .popup-button-icon > svg {
        width: 15px;
    }
}

/* Styles for screens 900px and above */
@media (min-width: 900px) {
    /* Popups */
    .popup-content {
        border-radius: 0;
        right: 0;
        top: 0;
        width: 500px;
    }
    
    /* StepIndicator + button */
    .game-onboarding .step-button-footer {
        position: absolute;
        width: inherit;
    }
}
