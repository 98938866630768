/* game-onboarding and game-welcome classes */

.game-onboarding, .game-welcome {
    align-items: center;
    background-image: linear-gradient(#026031, #189E5C, #026031);
    display: flex;
    flex-direction: column;
    font-family: var(--inter);
    gap: 72px;
    /* height: 100%; */
    height: calc(100vh - 78px);
    line-height: normal;
    position: relative;
    text-align: center;
    width: 100vw;
}

.game-onboarding h2, .game-welcome h2 {
    color: white;
    font-weight: var(--font-bold);
}

.game-onboarding p, .game-welcome p {
    color: white;
    font-weight: var(--font-regular);
}

.game-onboarding button, .game-welcome button {
    /* bottom: 8vh; */
    position: relative;
    width: 73%; /* 286px */
}

.game-onboarding button,
.game-onboarding input[type=submit],
.game-welcome button {
    background-color: #ffb0b5;
}

/* GameWelcome.jsx */

#game-title {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 23vh;
}

#caption {
    font-weight: var(--font-bold);
    letter-spacing: 2px;
    margin-top: 72px;
}

/* GameOnboarding.jsx */

.onboarding-title {
    margin-top: 12vh; /* 105px */
}

.game-onboarding > form {
    width: inherit;
}

.form {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    margin: 0 24px 40px 24px;
}

/* Form 1 - avatar selection */

.avatars {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
    margin-top: 32px;
}

.avatars input[type=radio] {
    display: none;
}

.avatars label {
    cursor: pointer;
    transition: transform 0.3s;
}

.avatars input[type=radio]:checked + label {
    transform: scale(1.1);
}

/* Forms 2 & 3 - text inputs */

.text-inputs {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
}

.text-input {
    background-color: white;
    border: 0;
    border-radius: 8px;
    font-weight: var(--font-regular);
    /* margin: 8px 0; */
    padding: 8px 12px;
    text-align: left;
}

.text-input label {
    font-size: small;
}

.text-input input {
    border: 0;
    font-size: large;
    padding: 4px 0;
}

.text-input input:focus {
    outline: none;
}

.invalid-input {
    font-size: small;
    text-align: center;
}

/* Styles for screen-width 400px and above */
@media (min-width: 400px) {
    .game-welcome button {
        width: 300px;
    }
    
    .game-onboarding > form {
        width: 400px;
    }
}

/* Styles for screen-width 600px and above */
@media (min-width: 600px) {
    #game-title > svg {
        width: 270px;
    }
}

/* Styles for screen-width 900px and above */
@media (min-width: 900px) {
    .game-onboarding {
        flex-direction: row;
        justify-content: center;
    }

    .onboarding-title {
        margin: 0;
    }

    .onboarding-title > svg {
        width: 160px;
    }
}

/* Styles for screen-height 570px and below */
@media (max-height: 570px) {
    #game-title > svg {
        height: 25%;
    }

    #caption {
        margin-top: 8%;
    }
}

/* Styles for screen-height 700px and below */
@media (max-height: 700px) {
    #game-title {
        margin-top: 16vh;
    }
}
