/* Styles for Insight.jsx */

.insight-content {
    border-radius: 0 !important;
    top: 0 !important;
}

.insight-header {
    align-self: stretch;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 72px 24px 16px 24px;
}

.insight-title {
    align-items: center;
    border-radius: 48px;
    display: flex;
    gap: 16px;
    padding: 8px 24px 8px 8px;
}

.insight-title > p {
    font-weight: var(--font-semi-bold);
}

.insight-image {
    align-self: stretch;
    height: 233px;
    overflow: hidden;
}

.insight-image > img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.insight-text {
    color: white;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 40px 32px 128px 32px;
}

.insight-text > p, .insight-text > ol {
    font-weight: var(--font-medium);
}

.insight-text > ol {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 0;
    padding-inline-start: inherit
}

.insight-text > ul {
    margin: 0;
    padding-inline-start: inherit
}

/* Differing Styles for ToolTips */

.tool-tip-header {
    align-self: stretch;
    display: flex;
    flex-direction: column;
}

.tool-tip-image {
    align-self: stretch;
    height: 233px;
    overflow: hidden;
}

.tool-tip-image > img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}
