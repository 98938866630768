.leaderboard-content {
    background-image: linear-gradient(#026031, #189E5C, #026031) !important;
    color: white;
    gap: 48px;
}

.leaderboard h2 {
    text-align: center;
}

.leaderboard-header {
    margin-top: 104px;
}

.leaderboard-top-ten {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 8px;
    width: 100%;
}

.leaderboard-footer {
    margin-bottom: 80px;
}

/* Player */

.players {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.player {
    align-items: center;
    align-self: stretch;
    background-color: white;
    border-radius: 48px;
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.15);
    color: black;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;
    padding: 12px 16px;
}

.player-details, .player-rank-avatar {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.player-rank {
    align-items: center;
    background-color: #FFDCDC;
    border-radius: 32px;
    color: #E04B77;
    display: flex;
    font-size: 16px;
    font-weight: var(--font-bold);
    height: 40px;
    justify-content: center;
    width: 40px;
}

.player-name-net-worth {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.player-net-worth {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    fill: #D9D9D9;
    font-size: 12px;
    font-weight: var(--font-medium);
    gap: 4px;
}

.player-percentage-inc {
    align-items: center;
    background: #F2F1EF;
    border-radius: 16px;
    display: flex;
    padding: 8px;
}

.current-player {
    background-color: #FFDCDC !important;
}

.current-player .player-percentage-inc {
    background-color: rgba(255, 146, 157, 0.38);
}

.current-player .player-net-worth {
    fill: #FF788C;
}

/* ToggleButton */

.toggle-container {
    cursor: pointer;
    display: inline-block;
    user-select: none; /* Prevent text selection */
}
  
.toggle-switch {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.40);
    border-radius: 24px;
    box-sizing: content-box;
    display: flex;
    height: 40px;
    padding: 4px;
    position: relative;
    text-align: center;
    transition: background-color 0.3s;
}

.toggle-switch-inner {
    height: inherit;
    position: absolute;
    width: calc(50% - 4px);
    z-index: 0;
}
  
.toggle-switch .toggle-switch-inner {
    background-color: #2BA864;
    border-radius: inherit;
    transition: transform 0.3s;
}

.toggle-switch > p {
    width: 50%;
    z-index: 1;
}
  
.toggle-switch.on .toggle-switch-inner {
    transform: translateX(100%);
}

/* Styles for screens 350px and below */
@media (max-width: 350px) {
    .player-rank-avatar > svg, .player-net-worth{
        display: none;
    }
}

/* Styles for screens 500px and above */
@media (min-width: 500px) {
    .leaderboard-content {
        width: 100% !important;
    }

    .leaderboard-top-ten {
        width: 500px;
    }
}
